<!--
 * @Description: 模板单项组件
 * @Autor: WangYuan
 * @Date: 2021-05-31 11:55:13
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-07-01 15:54:05
-->
<template>
 <div>
   <!-- 文章样式选择 -->
   <div v-if="father=='article'">
     <div
      class="mode-article-item flex-center"
      :class="[value == mode.mValue ? 'mode-article-item-active' : '']"
      @click="clickItem"
    >
    <svg-icon :icon-class="value" :class="value != mode.mValue?'grayscale':''" style="width:88px;height:64px;"></svg-icon>
    <svg-icon icon-class="active" v-if="value == mode.mValue"  style="width:16px;height:16px;position:absolute;right:-2px;bottom:-2px;z-index:99"></svg-icon>
    </div>
   </div>
   <!-- 默认选择 -->
    <el-tooltip
    v-else
    class="item"
    effect="light"
    :content="label"
    placement="bottom"
    :open-delay="200"
    :disabled="label == ''"
  >
    <div
      class="mode-select-item flex-center"
      :class="[value == mode.mValue ? 'mode-select-item-active' : '']"
      @click="clickItem"
    >
      <i v-if="icon" class="icon f20 f-bold" :class="icon"></i>
      <span v-else>{{ label }}</span>
    </div>
  </el-tooltip>
 </div>
</template>

<script>
export default {
  name: 'select-item',

  props: ['label', 'value', 'icon','father'],

  inject: ['mode'],

  methods: {
    clickItem () {
      this.mode.mValue = this.value
      this.mode.mTooltip = this.label
    }
  }
}
</script>

<style lang="scss" scoped>
.mode-article-item{
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 2px;
  cursor: pointer;
}
.grayscale{
  filter: grayscale(1)
}
.mode-article-item-active{
  position: relative;
}
.mode-article-item-active::after{
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #197AFF;
  z-index: 9999;
  border-radius: 2px;
}
.mode-select-item {
  padding: 8px 12px;
  min-width: 55px;
  border: 1px solid #dcdee0; /*no*/
  border-radius: 0;
  border-color: #ebedf0;
  font-size: 12px; /*no*/
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: $color-theme;
    border-color: $color-theme;
    background-color: #e0edff;
    i {
      color: $color-theme;
    }
  }

  i {
    color: #979797;
  }
}

.mode-select-item-active {
  color: $color-theme;
  border-color: $color-theme;
  background-color: #e0edff;
  i {
    color: $color-theme;
  }
}
</style>
