<!--
 * @Description: 魔方多维布局配置
 * @Autor: WangYuan
 * @Date: 2021-06-03 13:59:00
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-10-25 15:42:22
-->
<template>
	<div class="wrap">
		<div class="wrap-label">
			<span>选择样式</span>
		</div>
		<div class="wrap-body">
			<div class="type-list">
				<el-tooltip effect="light" :content="item.name" placement="bottom" v-for="(item, index) in list" :key="index">
					<div class="flex-center pointer info-tab" :class="[value.model == item.value ? 'mode-otherTab-item-active' : '']" @click="value.model = item.value">
						<svg-icon :icon-class="item.value" :class="value.model != item.value ? '' : 'bg-blue'" style="width:50px;height:36px;"></svg-icon>
						<svg-icon icon-class="active" v-if="value.model == item.value" style="width:16px;height:16px;position:absolute;right:0px;bottom:0px;z-index:99"></svg-icon>
					</div>
				</el-tooltip>
			</div>
		</div>
		<div v-if="value.model != 'infoType'">
			<div class="wrap-label">
				<span>展示导航描述</span>
			</div>
			<div class="wrap-body">
				<div>
					<el-switch v-model="value.showDes"> </el-switch>
					<!-- <el-select v-model="value.type" placeholder="请选择">
              <el-option
                v-for="item in navTypeArr"
                :key="item.id || index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
				</div>
			</div>
		</div>
		<div class="flex" v-if="value.model == 'infoType'">
			<div>
				<div class="wrap-label">
					<span>导航模式</span>
				</div>
				<div class="wrap-body">
					<div>
						<el-select v-model="value.type" placeholder="请选择">
							<el-option v-for="item in navTypeArr" :key="item.id || index" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</div>
				</div>
			</div>
			<div v-if="value.model == 'infoType'">
				<div class="wrap-label">
					<span>单行数量</span>
				</div>
				<div class="wrap-body">
					<div>
						<el-select v-model="value.max" placeholder="请选择">
							<el-option v-for="item in numArr" :key="item.id || index" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import schemaMixin from '@/mixin/schemaMixin';
export default {
	name: 'SchemaTab',

	mixins: [schemaMixin],

	components: {},

	props: {
		value: {
			type: Object,
			default: () => {},
		},
	},

	data() {
		return {
			list: [
				{ value: 'infoType', name: '默认模式' },
				{ value: 'threeType', name: '一左二右' },
				{ value: 'twoType', name: '一左一右' },
			],
			navTypeArr: [
				{
					id: '1634892959O8OC',
					label: '图标+文案',
					value: 'image-text',
				},
				{
					id: '1634892958XCIV',
					label: '仅文案',
					value: 'text',
				},
			],
			numArr: [
				{
					id: '16348930081xgk',
					label: '1行3个',
					value: 3,
				},
				{
					id: '1634893027qWrg',
					label: '1行4个',
					value: 4,
				},
				{
					id: '1634893046CdPQ',
					label: '1行5个',
					value: 5,
				},
			],
		};
	},

	methods: {},
};
</script>
<style lang="scss" scoped>
.mode-otherTab-item-active {
	position: relative;
}
.type-list {
	display: flex;
}
.pointer {
	cursor: pointer;
}
.info-tab {
	width: 50px;
	height: 36px;
}
.mode-otherTab-item-active::after {
	position: absolute;
	content: '';
	left: 0;
	top: 0;
	width: 48px;
	height: 34px;
	border: 1px solid #197aff;
	z-index: 99;
	border-radius: 2px;
}
.wrap {
	background: #fff;

	.wrap-label {
		padding: 0px 12px; /*no*/
		font-size: 14px; /*no*/
		color: #323233;

		span {
			&::before {
				content: '*';
				color: red;
				padding-right: 2px;
			}
		}
	}

	.wrap-body {
		padding: 20px; /*no*/

		.wrap-line {
			margin-top: 30px;
			height: 1px;
			background: #ebedf0;
		}
	}
}
</style>
