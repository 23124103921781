<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-10-18 09:52:15
-->
<template>
  <div>
    <config-item label='选择样式'>
      <div class="type-list">
          <el-tooltip effect="light" :content="item.name" placement="bottom"   v-for="(item, index) in list"
          :key="index">
        <div
          class="flex-center pointer info-tab"
          :class="[mValue.model == item.value ? 'mode-otherTab-item-active' : 'grayscale']"
          @click="mValue.model = item.value"
        >
          <svg-icon
            :icon-class="item.value"
            :class="mValue.model != item.value ? '' : 'bg-blue'"
            style="width:88px;height:64px;"
          ></svg-icon>
          <svg-icon
            icon-class="active"
            v-if="mValue.model == item.value"
            style="width:16px;height:16px;position:absolute;right:0px;bottom:0px;z-index:99"
          ></svg-icon>
        </div>
          </el-tooltip>
      </div>
    </config-item>
    <config-item label='视频地址'>
      <div class="pt10">
        <div class="flex col-center">
           <el-radio-group class="flex" v-model="mValue.uploadType" size="mini">
            <el-radio :label="'local'">本地视频</el-radio>
            <el-radio :label="'other'">第三方视频地址</el-radio>
          </el-radio-group>
        </div>
        <div v-if="mValue.uploadType=='local'" class="pt16">
          <el-upload
          class="upload-video"
           :action="action"
          :headers="headers"
          :with-credentials="true"
          :on-success="onSuccess"
          :before-remove="beforeRemove"
          :accept="accept"
          :file-list="fileList">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip"></div>
        </el-upload>
        </div>
        <div v-else class="pt10">
          <el-input
          v-model="mValue.src"
          placeholder="请输入视频地址链接"
          size="small"
        ></el-input>
        </div>
      </div>
    </config-item>
    <config-item label='播放形式'>
       <div class="pt10 flex col-center f12">
        <el-checkbox v-model="mValue.autoplay">自动播放</el-checkbox>
       <el-checkbox v-model="mValue.loop">循环播放</el-checkbox>
       </div>
    </config-item>
    <config-item label='视频封面图'>
      <div class="pt10">
        <div class="flex row-between">
        <Imgpond v-model="mValue.poster" :count="1" />
      </div>
      </div>
    </config-item>
    <config-item label='视频标题' v-if="mValue.model!='video1'">
      <div class="pt10">
        <el-input
          v-model="mValue.title"
          placeholder="请输入视频标题"
          size="small"
        ></el-input>
      </div>
    </config-item>
     <config-item label='标题对齐方向'  v-if="mValue.model!='video1'">
      <div class="pt10">
        <div class="flex row-between">
        <div></div>
        <el-radio-group v-model="mValue.titlePosition" size="mini"  >
            <el-radio-button label="left">居左</el-radio-button>
            <el-radio-button label="center">居中</el-radio-button>
          </el-radio-group>
      </div>
      </div>
    </config-item>
  </div>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import global from '@/config/global'
export default {
  name: "SchemaVideo",

  mixins: [schemaMixin],
  data() {
    return{
       maxFileNum: this.max || 10,
      action: global.baseApi + '/api/common/upload?is_cdn=1',
      // action:'https://huique.xiebanyun.com/upload',
      accept: 'video/mp4',
      type:'',
      video:null,
       list: [
        {value:"video1",name:'默认'},
        {value:"video2",name:'标题样式1'},
        // {value:"video3",name:'标题样式2'},
      ],
      fileList: []
    }
  },
  watch: {
    fileList(val){
      console.log(val);
      if(val.length==0){
        this.mValue.src=''
      }
    }
  },
  created() {
    if(this.mValue){
       if(this.mValue.src&&this.mValue.src!=''){
        this.fileList.push({name:this.mValue.src,url:this.mValue.src})
      }
    }else{
      this.mValue={
        model:'video1',
         src:"https://saas-chengdu.oss-cn-chengdu.aliyuncs.com/uploads/20220826/ef7acb68c28c9fb3ff906d831d74d4fe.mp4",
			   uploadType:"local",
			   autoplay:false,
			   loop:false,
			   poster:"",
			   title:"",
			   titlePosition:"left"
      }
    }
    console.log(this.mValue,'初始化');
   
  },
  methods: {
      onSuccess(response, file, fileList) {
      console.log(response)
      if(response.code==1){
        this.fileList=[{
          name:response.data.url,
          url:response.data.url
        }]
        this.mValue.src=response.data.url
        // this.fileList.push({
        //   url:response.url+'?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast,ar_auto'
        // })
        // this.mValue=response.data.url
      }
      // if (response.url) {
      //      this.fileList.push({
      //      url: response.url+'?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast,ar_auto'
      //     //  url: response.url
      //     })
      //     this.video=response.url
      //   console.log(this.type)
      //   this.$emit('input', this.fileList)
      // } else {
      //   this.$message.error(response.msg)
      // }
    },
      beforeRemove(file, fileList) {
        // return this.$confirm(`确定移除该文件吗？`);
       return this.$confirm('确定移除该文件吗, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           setTimeout(()=>{
            this.fileList=[]
            this.mValue.src=''
          },100)
        }).catch(() => {
          console.log(2,fileList);
          setTimeout(()=>{
            this.fileList=[{name:this.mValue.src,url:this.mValue.src}]
          },100)
        });
      }
  }
};
</script>
<style lang="scss" scoped>
.po-re{
  position: relative;
  height: 100%;
}
::v-deep .el-message-box__message{
  word-break: break-all !important;
}
.mode-otherTab-item-active {
  position: relative;
}
.type-list { 
  display: flex;
  flex-wrap: wrap;
}
.grayscale{
  filter: grayscale(1)
}
.pointer {
  cursor: pointer;
}
.info-tab {
  width: 50px;
  height: 36px;
}
.upload-video ::v-deep .el-upload-list__item{
  width: 100% !important;
  height: auto !important;
   text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    line-height: normal;
}
::v-deep .el-upload-list__item .el-icon-close{
  top: 2px;
}
::v-deep .el-icon-close-tip{
  display: none !important;
}
.upload-video ::v-deep .el-upload-list__item-name{
  width: 190px;
}

.mode-otherTab-item-active::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 49px;
  height: 35px;
  border: 1px solid #197aff;
  z-index: 9999;
  border-radius: 2px;
}
::v-deep .el-checkbox__label{
  font-size: 12px;
  line-height: 12px;
}
::v-deep .el-radio__label{
  font-size: 12px;
  line-height: 12px;
}
.trigger {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0 5%;
  z-index: 10;
  font-size: 14px;
}
.success-tag{
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 2px;
  background: conic-gradient(from 90deg at 50% 0,  #13ce66 0,  #13ce66 45deg, transparent 45.1deg);
  border-radius: 0 4px 0 0;
  border-left: 10px;
}
.play-icon{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.upload:hover {
  .trigger {
    display: flex;
  }
  .success-tag,.play-icon{
    display: none;
  }
}


</style>