<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-08-12 17:23:08
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-10-18 09:42:37
-->
<template>
	<el-dialog title="请选择跳转页面" :visible.sync="show" width="800px" :close-on-click-modal="false">
		<!-- <div class="tabs mb30">
      <div
        class="tab flex-center"
        :class="[value.type == tab.type?'tab-active':'']"
        v-for="(tab,i) in tabs"
        :key="i"
        @click="changeTab(tab.type)"
      >
        {{tab.label}}
      </div>
  </div> -->
		<div class="flex">
			<el-tabs tab-position="left" v-model="activeType" @tab-click="handleClick">
				<el-tab-pane label="页面模块" name="info"></el-tab-pane>
				<el-tab-pane label="文章" name="article"></el-tab-pane>
				<el-tab-pane label="相册" name="photo"></el-tab-pane>
				<el-tab-pane label="活动" name="event"></el-tab-pane>
				<el-tab-pane label="投票" name="vote"></el-tab-pane>
				<el-tab-pane label="表单/问卷" name="form"></el-tab-pane>
				<el-tab-pane label="站外链接" name="link"></el-tab-pane>
				<el-tab-pane label="微信小程序" name="wxapp"></el-tab-pane>
				<el-tab-pane label="微信视频号视频" name="wx_video"></el-tab-pane>
			</el-tabs>
			<div class="pl12" style="width: 600px">
				<!-- 页面模块 -->
				<template v-if="activeType == 'info'">
					<div>
						<div class="page-title">主页面</div>
						<div class="flex flex-wrap">
							<div v-for="(item, index) in fixedPages" :key="index">
								<el-tooltip
									v-if="item.need_auth && userInfo.edition_key == 'base'"
									class="item"
									effect="dark"
									content="此模块为高级功能，请升级版本后使用"
									placement="top"
								>
									<div :class="value.type == item.type && value.id == item.id ? 'active-tag' : 'page-tag'" :style="[getStyle(item)]">
										{{ item.name }}
									</div>
								</el-tooltip>

								<div @click="changePage(item, item.type)" :class="value.type == item.type && value.id == item.id ? 'active-tag' : 'page-tag'" v-else>
									{{ item.name }}
								</div>
							</div>
						</div>
						<div class="page-title">模块页面</div>
						<div class="flex flex-wrap">
							<div v-for="(item, index) in infoPages" :key="index">
								<el-tooltip
									v-if="item.need_auth && userInfo.edition_key == 'base'"
									class="item"
									effect="dark"
									content="此模块为高级功能，请升级版本后使用"
									placement="top"
								>
									<div :class="value.type == item.type && value.id == item.id ? 'active-tag' : 'page-tag'" :style="[getStyle(item)]">
										{{ item.name }}
									</div>
								</el-tooltip>
								<div @click="changePage(item, item.type)" :class="value.type == item.type && value.id == item.id ? 'active-tag' : 'page-tag'" v-else>
									{{ item.name }}
								</div>
							</div>
							<el-tooltip v-if="father == 'button'" class="item" effect="dark" content="此模块仅在小程序中显示" placement="top">
								<div @click="changePage(service, service.type)" :class="value.type == service.type && value.id == service.id ? 'active-tag' : 'page-tag'">
									{{ service.name }}
								</div>
							</el-tooltip>
						</div>
						<div class="page-title">微页面</div>
						<div class="flex flex-wrap">
							<div
								@click="changePage(item, 'custom')"
								:class="value.type == 'custom' && value.id == item.id ? 'active-tag' : 'page-tag'"
								v-for="(item, index) in customPages"
								:key="index"
							>
								{{ item.name }}
							</div>
						</div>
					</div>
				</template>
				<!-- 文章 -->
				<template v-if="activeType == 'article'">
					<div>
						<div class="flex row-between">
							<div class="mb12 flex col-center">
								<div class="mr6">跳转至文章分类</div>
								<el-switch v-model="showArticleType" @change="changeArticleType"> </el-switch>
								<el-button class="ml12" @click="goArticle('cms/archives?ref=addtabs')" size="mini" type="primary">文章管理</el-button>
							</div>
							<el-input prefix-icon="el-icon-search" class="w200" v-model="params.search" size="mini" placeholder="请输入搜索内容"></el-input>
						</div>
						<LayoutTable
							v-if="!showArticleType"
							ref="articleTable"
							:url="`/api/admin/v1/cms/archives`"
							:loading.sync="loading"
							:params="params"
							:selection_radio="true"
							:chooseId="articleId != 0 ? articleId : -1"
							@selection-change="articleSelectionChange"
						>
							<el-table-column type="selection" width="55"> </el-table-column>
							<el-table-column label="文章" width="460">
								<template slot-scope="props">
									{{ props.row.title }}
								</template>
							</el-table-column>
							<el-table-column label="">
								<template slot-scope="props">
									<img v-if="props.row.image" class="w42 h42 r4" :src="props.row.image" />
								</template>
							</el-table-column>
						</LayoutTable>
						<div v-else>
							<div class="mb12">选择分类</div>
							<div class="w300">
								<el-select v-model="articleId" placeholder="请选择">
									<el-option v-for="item in articleType" :key="item.id" :label="item.name" :value="item.id"> </el-option>
								</el-select>
								<!-- <el-cascader
              v-model="articleId"
              :props="elprops"
              :options="articleType"
              @change="handleChange">
              </el-cascader> -->
							</div>
						</div>
					</div>
				</template>
				<!-- 相册 -->
				<template v-if="activeType == 'photo'">
					<LayoutTable
						ref="photoTable"
						:url="`/api/operation.live/index`"
						:loading.sync="loading"
						:params="params"
						:selection_radio="true"
						:chooseId="articleId != 0 ? articleId : -1"
						@selection-change="photoSelectionChange"
					>
						<el-table-column type="selection" width="55"> </el-table-column>
						<el-table-column label="标题" width="460">
							<template slot-scope="props">
								{{ props.row.title }}
							</template>
						</el-table-column>
						<el-table-column label="">
							<template slot-scope="props">
								<img v-if="props.row.files[0]" class="w42 h42 r4" :src="props.row.files[0].image" />
							</template>
						</el-table-column>
					</LayoutTable>
				</template>
				<!-- 活动 -->
				<template v-if="activeType == 'event'">
					<div>
						<div class="mb12 flex col-center row-between">
							<el-button @click="goArticle('meeting/huique/index_v1')" size="mini" type="primary">创建活动</el-button>
							<!-- <el-input prefix-icon="el-icon-search" class="w200" v-model="params.search" size="mini" placeholder="请输入搜索内容"></el-input> -->
						</div>
						<LayoutTable
							ref="eventTable"
							:url="`/api/admin/v1/huique/meeting/index`"
							:loading.sync="loading"
							:params="params"
							:selection_radio="true"
							:chooseId="articleId != 0 ? articleId : -1"
							@selection-change="eventSelectionChange"
						>
							<el-table-column type="selection" width="55"> </el-table-column>
							<el-table-column label="活动标题" width="460">
								<template slot-scope="props">
									<span v-if="props.row.title">{{ props.row.title }}</span>
									<span v-if="props.row.name">{{ props.row.name }}</span>
								</template>
							</el-table-column>
							<el-table-column label="">
								<template slot-scope="props">
									<img v-if="props.row.poster" class="w42 h42 r4" :src="props.row.poster" />
								</template>
							</el-table-column>
						</LayoutTable>
					</div>
				</template>
				<!-- 投票 -->
				<template v-if="activeType == 'vote'">
					<div>
						<div class="mb12 flex col-center row-between">
							<el-button @click="goArticle('account.php/vote/votelist')" size="mini" type="primary">创建投票</el-button>
							<!-- <el-input prefix-icon="el-icon-search" class="w200" v-model="params.search" size="mini" placeholder="请输入搜索内容"></el-input> -->
						</div>
						<LayoutTable
							ref="voteTable"
							:url="`/api/admin/v1/vote/vote`"
							:loading.sync="loading"
							:params="params"
							:selection_radio="true"
							:chooseId="articleId != 0 ? articleId : -1"
							@selection-change="voteSelectionChange"
						>
							<el-table-column type="selection" width="55"> </el-table-column>
							<el-table-column label="投票标题" width="460">
								<template slot-scope="props">
									<span v-if="props.row.vote_name">{{ props.row.vote_name }}</span>
								</template>
							</el-table-column>
							<el-table-column label="">
								<template slot-scope="props">
									<img v-if="props.row.image" class="w42 h42 r4" :src="getVoteImg(props.row.image)" />
								</template>
							</el-table-column>
						</LayoutTable>
					</div>
				</template>
				<!-- 表单 -->
				<template v-if="activeType == 'form'">
					<div>
						<div class="mb12 flex col-center row-between">
							<el-button @click="goArticle('meeting/huique/form_request')" size="mini" type="primary">创建表单</el-button>
							<!-- <el-input prefix-icon="el-icon-search" class="w200" v-model="params.search" size="mini" placeholder="请输入搜索内容"></el-input> -->
						</div>
						<LayoutTable
							ref="formTable"
							:url="`/api/admin/v1/huique/meeting/request_form`"
							:loading.sync="loading"
							:params="params"
							:selection_radio="true"
							:chooseId="articleId != 0 ? articleId : -1"
							@selection-change="handleSelectionChange"
						>
							<el-table-column type="selection" width="55"> </el-table-column>
							<el-table-column label="名称" width="600">
								<template slot-scope="props">
									{{ props.row.name }}
								</template>
							</el-table-column>
						</LayoutTable>
					</div>
				</template>
				<template v-if="activeType == 'link'">
					<el-form label-position="top" style="width: 400px">
						<el-form-item label="链接地址" required>
							<el-input v-model="web.id" placeholder="请输入链接地址"></el-input>
							<div class="f-grey f14">
								链接格式，例：“https://baidu.com”
								<span class="link ml10" @click="openUrl('https://developers.weixin.qq.com/miniprogram/dev/framework/ability/domain.html')">查看教程</span>
							</div>
						</el-form-item>
					</el-form>
				</template>
				<template v-if="activeType == 'wxapp'">
					<div class="mb12 flex col-center">
						<div class="mr6">自定义小程序</div>
						<el-switch v-model="wxapp.other"> </el-switch>
					</div>
					<el-select v-if="!wxapp.other" v-model="wxapp.id" placeholder="请选择要跳转的微信小程序" size="mini" style="width: 250px">
						<el-option v-for="(page, i) in wxappPages" :key="i" :label="page.name" :value="page.id"> </el-option>
					</el-select>
					<el-form label-position="top" style="width: 450px" v-else>
						<el-form-item label="小程序AppID" required>
							<el-input v-model="wxapp.id" placeholder="请输入"></el-input>
						</el-form-item>
						<el-form-item label="小程序路径">
							<el-input v-model="wxapp.url" placeholder="请输入链接地址"></el-input>
							<div class="f-grey f14 mt12">
								仅支持小程序之间的跳转，不支持从其他渠道跳转小程序
								<span class="link ml10" @click="openUrl('https://developers.weixin.qq.com/miniprogram/dev/api/navigate/wx.navigateToMiniProgram.html')"
									>查看教程</span
								>
							</div>
							<div class="f-grey f14">小程序路径如果为空则跳转到小程序首页</div>
							<div class="f-grey f14">小程序路径格式如：page/index/index</div>
						</el-form-item>
					</el-form>
				</template>
				<template v-if="activeType == 'wx_video'">
					<el-form label-position="top" style="width: 450px">
						<el-form-item label="视频号id" required>
							<el-input v-model="wx_video.id" placeholder="请输入"></el-input>
						</el-form-item>
						<el-form-item label="视频feedId">
							<el-input v-model="wx_video.feedId" placeholder="请输入"></el-input>
						</el-form-item>
					</el-form>
					<div class="f-grey f14 mt12">
						<div class="lh-18" style="width:450px">
							仅支持跳转至微信视频号,若不填写视频feedId则跳转至视频号主页（跳转至视频号主页需要小程序与视频号的主体相同或为关联主体）
						</div>
						<div class="flex  mt10">
							<div class="link" @click="openUrl('https://developers.weixin.qq.com/miniprogram/dev/framework/open-ability/channels-profile.html')">
								查看视频号主页教程
							</div>
							<div class="link ml10" @click="openUrl('https://developers.weixin.qq.com/miniprogram/dev/framework/open-ability/channels-activity.html')">
								查看视频号视频教程
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="show = false">取 消</el-button>
			<el-button type="primary" @click="confirm">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import LayoutTable from '@/components/LayoutTable';
import { getGoodsType } from '@/api/goods';
import { debounce } from '@/utils/utils';
import { getUrl } from '@/api/project';
export default {
	name: 'JumpDialog',
	inject: ['value'],
	components: {
		LayoutTable,
	},
	props: {
		father: {
			default: () => '',
		},
	},
	data() {
		return {
			dashboard_url: '',
			show: false,
			elprops: {
				children: 'childlist',
				label: 'name',
				value: 'id',
			},
			service: { id: '/customerService', name: '小程序客服', type: 'info' },
			wxappPages: [{ id: 'huique', name: '活动小程序' }],
			activeType: '',
			loading: true,
			params: {},
			showArticleType: false,
			articleType: [],
			articleId: 0,
			articleItem: [],
			web: {
				name: '',
				id: '',
			},
			wxapp: {
				other: false,
				id: '',
				url: '',
			},
			wx_video: {
				id: '',
				feedId: '',
			},
		};
	},
	watch: {
		'params.search': {
			handler(val) {
				console.log(val);
				this.loadTable();
			},
			deep: true,
		},
		activeType(val) {
			// this.params.search = ''
			this.$set(this.params, 'search', '');
			if (val == 'article' || val == 'event' || val == 'vote' || val == 'form') {
				this.$nextTick(() => {
					this.$refs[`${val}Table`].load();
				});
			}
			if (val != 'wxapp') {
				this.value.url = '';
			}
		},
		show(val) {
			if (val) {
				if (!this.value.type) {
					this.activeType = 'info';
				} else if (this.value.type == 'info' || this.value.type == 'fixed' || this.value.type == 'custom' || this.value.type == 'photo') {
					if (this.value.type == 'photo') {
						this.activeType = 'photo';
						this.articleId = Number(this.getUrlParam('id', this.value.id.split('?')[1]));
						return;
					}
					if (this.value.type == 'info' && (this.value.id.indexOf('/pages/navList/navList?id') != -1 || this.value.id.indexOf('/pagesA/news/news_item?id') != -1)) {
						this.activeType = 'article';
						if (this.value.id.indexOf('/pages/navList/navList?id') != -1) {
							this.articleId = Number(this.getUrlParam('id', this.value.id.split('?')[1]));
							this.showArticleType = true;
						} else {
							this.articleId = Number(this.getUrlParam('id', this.value.id.split('?')[1]));
							this.showArticleType = false;
						}
					} else {
						this.activeType = 'info';
					}
				} else if (this.value.type == 'event' || this.value.type == 'event_xieban') {
					this.activeType = 'event';
					if (this.value.id != '' && this.value.id) {
						this.articleId = this.value.id;
					}
				} else if (this.value.type == 'vote') {
					this.activeType = this.value.type;
					if (this.value.id != '' && this.value.id) {
						this.articleId = this.value.id;
					}
				} else if (this.value.type == 'form') {
					this.activeType = this.value.type;
					if (this.value.id.indexOf('https://form.xiebanyun.com/project/form') != -1) {
						this.articleId = Number(this.getUrlParam('id', this.value.id.split('?')[1]));
					}
				} else if (this.value.type == 'link') {
					this.activeType = this.value.type;
					this.web.id = this.value.id;
				} else if (this.value.type == 'wxapp') {
					this.activeType = this.value.type;
					this.wxapp.id = this.value.id;
					if (this.value.id != 'huique') {
						this.wxapp.other = true;
						this.wxapp.url = this.value.url;
					}
				} else if (this.value.type == 'wx_video') {
					this.activeType = this.value.type;
					this.wx_video.id = this.value.id;
					if (this.value.feedId) {
						this.wx_video.feedId = this.value.feedId;
					}
				} else {
					this.activeType = this.value.type;
				}
			}
		},
		'wxapp.other': {
			handler(val) {
				if (!val) {
					this.wxapp.id = '';
					this.wxapp.url = '';
				} else {
					if (this.value.id != 'huique') {
						// this.wxapp.id = this.value.id
						// this.wxapp.url = this.value.url
					} else {
						this.wxapp.id = '';
					}
				}
			},
			deep: true,
		},
	},
	computed: {
		...mapGetters(['project', 'fixedPages', 'infoPages', 'userInfo']),
		customPages() {
			let cloneProject = this.$cloneDeep(this.project);
			let arr = cloneProject.pages;
			var result = [];
			var obj = {};
			for (var i = 0; i < arr.length; i++) {
				if (!obj[arr[i].id]) {
					result.push(arr[i]);
					obj[arr[i].id] = true;
				}
			}
			return result;
		},
	},
	created() {
		this.initType();
	},
	handleClick(tab, event) {},
	methods: {
		getVoteImg(e) {
			return JSON.parse(e)[0];
		},
		getStyle(item) {
			console.log(item);
			let result = {};
			if (item.need_auth && this.userInfo.edition_key == 'base') {
				result.opacity = 0.5;
				result.cursor = 'not-allowed';
			}
			return result;
		},
		async initType() {
			let res = await getGoodsType();
			if (res.code == 200) {
				this.articleType = [];
				this.getTreeDatas(res.data);
				this.articleType.unshift({ id: 0, name: '全部' });
			}
		},
		loadTable: debounce(function() {
			if (this.activeType && this.$refs[`${this.activeType}Table`]) {
				this.$refs[`${this.activeType}Table`].load();
			}
		}, 500),
		// 跳转至文章管理
		goArticle(url) {
			getUrl({ url }).then((res) => {
				// this.dashboard_url = res.data.dashboard_url
				if (url == 'meeting/huique/index_v1') {
					let random_num = Math.floor(Math.random() * (1000 - 100) + 100);
					window.open(res.data.dashboard_url.split('/account.php/')[0] + `/account.php/active/activelist?index=${random_num}&ref=addtabs`);
				} else if (url == 'account.php/vote/votelist') {
					let random_num = Math.floor(Math.random() * (1000 - 100) + 100);
					window.open(res.data.dashboard_url.split('/account.php/')[0] + `/account.php/vote/votelist?index=${random_num}&ref=addtabs`);
				} else {
					window.open(res.data.dashboard_url);
				}
			});
		},
		getTreeDatas(data) {
			// 循环遍历json数据
			for (var i = 0; i < data.length; i++) {
				if (data[i].childlist.length < 1) {
					// children若为空数组，则将children设为undefined
					// data[i].childlist=undefined;
					this.articleType.push(data[i]);
				} else {
					this.articleType.push(data[i]);
					// children若不为空数组，则继续 递归调用 本方法
					this.getTreeDatas(data[i].childlist);
				}
			}
			// return data;
		},
		openUrl(url) {
			window.open(url);
		},
		reset(value) {
			console.log(value);
			this.value = value;
		},
		getUrlParam(name, str) {
			let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
			let r = str.match(reg);
			console.log(name, str, r);
			if (r != null) {
				return unescape(r[2]);
			}
			return null;
		},
		changeArticleType(val) {
			if (val) {
				if (this.value.id.indexOf('/pages/navList/navList?id') != -1) {
					this.articleId = Number(this.getUrlParam('id', this.value.id.split('?')[1]));
				} else {
					this.articleId = 0;
				}
			} else {
				if (this.value.id.indexOf('/pagesA/news/news_item?id') != -1) {
					this.articleId = Number(this.getUrlParam('id', this.value.id.split('?')[1]));
				}
			}
		},
		getTreeData(data) {
			// 循环遍历json数据
			for (var i = 0; i < data.length; i++) {
				if (data[i].childlist) {
					if (data[i].childlist.length < 1) {
						// children若为空数组，则将children设为undefined
						data[i].childlist = null;
					} else {
						// children若不为空数组，则继续 递归调用 本方法
						this.getTreeData(data[i].childlist);
					}
				}
			}
			return data;
		},
		handleChange(e) {
			console.log(e);
		},
		confirm() {
			// if(Array.isArray(this.value)){
			//   let obj = Object.assign({}, this.value)
			//   this.value = obj
			// }
			if (this.activeType == 'info') {
				this.show = false;
			} else if (this.activeType == 'article') {
				if (!this.showArticleType) {
					console.log(this.articleItem);
					if (this.articleItem.length == 0) {
						this.$message({
							message: '请选择跳转文章',
							type: 'warning',
						});
					} else {
						this.$set(this.value, 'type', 'info');
						this.$set(this.value, 'id', `/pagesA/news/news_item?id=${this.articleItem[0].id}`);
						this.show = false;
					}
				} else {
					let name = this.articleType.find((item) => item.id == this.articleId).name;
					this.$set(this.value, 'type', 'info');
					this.$set(this.value, 'id', `/pages/navList/navList?id=${this.articleId}&title=article&label=${name}`);
					this.show = false;
				}
			} else if (this.activeType == 'photo') {
				if (this.articleItem.length == 0) {
					this.$message({
						message: '请选择跳转相册内容',
						type: 'warning',
					});
				} else {
					this.$set(this.value, 'type', 'photo');
					this.$set(this.value, 'id', `/pagesA/photoAlbum/detail?id=${this.articleItem[0].id}`);
					this.show = false;
				}
			} else if (this.activeType == 'event') {
				if (this.articleItem.length == 0) {
					this.$message({
						message: '请选择跳转活动',
						type: 'warning',
					});
				} else {
					this.$set(this.value, 'type', !isNaN(this.articleItem[0].start_time) ? 'event' : 'event_xieban');
					this.$set(this.value, 'id', this.articleItem[0].id);
					this.show = false;
				}
			} else if (this.activeType == 'vote') {
				if (this.articleItem.length == 0) {
					this.$message({
						message: '请选择跳转投票',
						type: 'warning',
					});
				} else {
					this.$set(this.value, 'type', 'vote');
					this.$set(this.value, 'id', this.articleItem[0].id);
					this.show = false;
				}
			} else if (this.activeType == 'form') {
				if (this.articleItem.length == 0) {
					this.$message({
						message: '请选择跳转表单',
						type: 'warning',
					});
				} else {
					this.$set(this.value, 'type', 'form');
					this.$set(
						this.value,
						'id',
						`https://form.xiebanyun.com/project/form?code=${this.articleItem[0].code}_${window.btoa(this.articleItem[0].id)}&id=${this.articleItem[0].id}`
					);
					this.show = false;
				}
			} else if (this.activeType == 'link') {
				if (this.web.id.length == 0) {
					this.$message({
						message: '请输入链接地址',
						type: 'warning',
					});
				} else {
					this.$set(this.value, 'type', 'link');
					this.$set(this.value, 'id', this.web.id);
					this.show = false;
				}
			} else if (this.activeType == 'wxapp') {
				if (this.wxapp.id.length == 0) {
					this.$message({
						message: '请配置或选择跳转小程序',
						type: 'warning',
					});
				} else {
					this.$set(this.value, 'type', 'wxapp');
					this.$set(this.value, 'id', this.wxapp.id);
					if (this.wxapp.other) {
						this.$set(this.value, 'url', this.wxapp.url);
					}
					this.show = false;
				}
			} else if (this.activeType == 'wx_video') {
				if (this.wx_video.id.length == 0) {
					this.$message({
						message: '请完善必填项',
						type: 'warning',
					});
				} else {
					this.$set(this.value, 'type', 'wx_video');
					this.$set(this.value, 'id', this.wx_video.id);
					if (this.wx_video.feedId) {
						this.$set(this.value, 'feedId', this.wx_video.feedId);
					} else {
						this.$set(this.value, 'feedId', '');
					}
					this.show = false;
				}
			}
			console.log(this.value, this.activeType);
		},
		articleSelectionChange(e) {
			this.articleItem = e;
		},
		photoSelectionChange(e) {
			console.log(e);
			this.articleItem = e;
		},
		eventSelectionChange(e) {
			this.articleItem = e;
		},
		voteSelectionChange(e) {
			this.articleItem = e;
		},
		handleSelectionChange(e) {
			this.articleItem = e;
		},
		open() {
			this.show = true;
		},
		changePage(item, type) {
			this.$set(this.value, 'type', type);
			this.$set(this.value, 'id', item.id);
		},
		changeTab(type) {
			this.$set(this.value, 'type', type);
			this.$set(this.value, 'id', '');
		},
		setPageId(id) {
			this.$set(this.value, 'id', id);
		},
		setType(type) {
			this.$set(this.value, 'type', type);
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .el-tabs {
	width: 140px !important;
}
::v-deep .el-icon-search {
	height: 30px;
}
::v-deep .el-form--label-top .el-form-item__label {
	padding: 0;
}
::v-deep .el-form-item {
	margin-bottom: 0;
}
::v-deep .el-dialog__header {
	border-bottom: 1px solid #ebeef5;
}
::v-deep .el-dialog__footer {
	border-top: 1px solid #ebeef5;
}
.page-tag {
	padding: 9px 18px;
	border: 1px solid #dcdfe6;
	margin-right: 6px;
	margin-bottom: 10px;
	border-radius: 4px;
	color: #606266;
	cursor: pointer;
}
.active-tag {
	padding: 9px 18px;
	color: #1890ff;
	border: 1px solid #1890ff;
	margin-right: 6px;
	margin-bottom: 10px;
	border-radius: 4px;
	cursor: pointer;
	position: relative;
}
.active-tag::after {
	content: '';
	position: absolute;
	right: -1px;
	bottom: -1px;
	width: 16px;
	height: 16px;
	background: url('../../../assets/image/active.png');
	background-size: cover;
	border-radius: 4px;
}
::v-deep .el-table__header .el-checkbox {
	display: none;
}
.link {
	color: #1890ff;
	text-decoration: underline;
	cursor: pointer;
}
.page-title {
	margin-bottom: 12px;
}
.tabs {
	display: flex;

	.tab {
		width: 100px;
		height: 30px;
		color: #979797;
		border: solid 1px #ebedf0;
		cursor: pointer;
	}

	.tab-active {
		color: $color-theme;
		border: solid 1px $color-theme;
		background: #e0edff;
	}
}
</style>
